import ReactGA from 'react-ga';
import { ClientJS } from 'clientjs';
import axios from 'axios';

const determineClientId = async endpoint => {
  let ip;

  try {
    const {
      data: { ip: ipAddress },
    } = await axios(endpoint);
    ip = ipAddress;
  } catch (e) {
    console.log(e);
  }

  const client = new ClientJS();
  const ua = client.getBrowserData().ua;
  const canvas = client.getCanvasPrint();
  const engine = client.getEngineVersion();
  const plugins = client.getPlugins();
  const screen = client.getScreenPrint();
  const fonts = client.getFonts();
  const isWindows = client.isWindows();
  const isMac = client.isMac();
  const isLinux = client.isLinux();
  const os = client.getOS();
  const timeZone = client.getTimeZone();
  const language = client.getLanguage();
  const isLocalStorage = client.isLocalStorage();
  const isSessionStorage = client.isSessionStorage();
  const fingerprint = client.getCustomFingerprint(
    ua,
    canvas,
    engine,
    plugins,
    screen,
    fonts,
    isWindows,
    isMac,
    isLinux,
    os,
    timeZone,
    language,
    isLocalStorage,
    isSessionStorage,
    ip
  );

  return fingerprint;
};

export const onClientEntry = async (_, pluginOptions) => {
  const userId = await determineClientId(pluginOptions.endpoint);

  ReactGA.initialize(pluginOptions.trackingId, {
    gaOptions: {
      storage: 'none',
      storeGac: false,
      clientId: `${userId}`,
    },
  });

  ReactGA.pageview(window.location.pathname);
  ReactGA.set({ anonymizeIp: true });
};

export const onRouteUpdate = ({ location }) => {
  ReactGA.set({ page: location.pathname, anonymizeIp: true });
  ReactGA.pageview(location.pathname);
};
