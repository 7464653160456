module.exports = [{
      plugin: require('../../../../shared/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/ester-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"aec7a07b84f6f8d0143104a42ef7e98a"},
    },{
      plugin: require('../../../../shared/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://ester.co","stripQueryString":true},
    },{
      plugin: require('../../../../shared/node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[],"useHydrate":true},
    },{
      plugin: require('../plugins/ga-cookieless/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-77336260-1","endpoint":"https://admin.home.estr.xyz/wp-json/ester/v1/me"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
